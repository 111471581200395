
import { requestToken } from '@/utils/request.js'

/*---------- 大屏列表 ------------*/
// 获取大屏列表
export const screenMerchant = (data) => {
  return requestToken('/screenUser/queryList', data)
}
// 修改大屏名称
export const updateScreenMerchant = (data) => {
  return requestToken('/screenUser/updateSingle', data)
}

/*---------- 大屏1 ------------*/
// 大屏1 报警状态简报
export const alarmStatus = (data) => {
  return requestToken('/screen/alarmStatus', data)
}

/*---------- 大屏2 ------------*/
// 获取省区域电站数量
export const stationNum = (data) => {
  return requestToken('/screen/stationNum', data)
}


// 大屏计划发电 /screen/planPowerData
export const planPowerData = (data) => {
  return requestToken('/screen/planPowerData', data)
}

// 本年度计划发电量 /screen/planPower
export const yearPlanPower = (data) => {
  return requestToken('/screen/planPower', data)
}
